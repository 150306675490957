var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{class:_vm.isMobile ? 'checkoutHeightxs' : ''},[_c('h2',{directives:[{name:"show",rawName:"v-show",value:(_vm.isMobile),expression:"isMobile"}],staticClass:"stepsTitle",domProps:{"innerHTML":_vm._s(_vm.title)}}),(false)?_c('AllowSubstitutions',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isViewMode && _vm.showSubstitution),expression:"!isViewMode && showSubstitution"}]}):_vm._e(),_c('div',{staticClass:"orderItemsGroup"},[_c('div',{staticClass:"cart-section"},_vm._l((_vm.currentCartDetails.fulfillments),function(fulFillment,index){return _c('div',{key:`${fulFillment.fulfillmentMethodID}-${index}`},[_c('div',{staticClass:"cart-order-header"},[_c('div',{staticClass:"cart-order-title"},[_c('div',{staticClass:"row items-center"},[_c('div',{staticClass:"col-auto"},[_c('span',{staticClass:"icon"},[_c('SvgIcon',{attrs:{"color":"primaryOnBody","icon":fulFillment.codeName}})],1)]),_c('div',{staticClass:"col q-pl-sm"},[_c('strong',[_vm._v(_vm._s(fulFillment.displayName))])])])]),_c('div',{staticClass:"cart-order-price text-right"},[_vm._v(" Total ")])]),_vm._l((fulFillment.shoppingCartItems),function(shoppingCartItem,index){return _c('div',{key:`${shoppingCartItem.orderLineItemID}-${index}`,staticClass:"cart-order"},[_c('div',{class:[
                'cart-product sd-spacing q-ml-none',
                shoppingCartItem.linkedProducts &&
                shoppingCartItem.linkedProducts.length > 0
                  ? 'substitution-product'
                  : _vm.substitute(
                      shoppingCartItem.orderLineItemID,
                      shoppingCartItem.allowSubstitute
                    )
                  ? 'substitution-product'
                  : '',
              ]},[_c('div',{staticClass:"cart-produt-data"},[_c('div',{staticClass:"produt-data flex items-start"},[_c('div',{staticClass:"product-image"},[_c('box-image',{attrs:{"link":{
                        name: 'Detail',
                        params: { seoName: shoppingCartItem.seoName },
                      },"imageSrc":shoppingCartItem.image,"width":_vm.isMobile ? '80px' : '86px'}})],1),_c('div',{staticClass:"co-product-details"},[_c('div',{staticClass:"product--title"},[_c('h3',{staticClass:"ellipsis"},[_c('router-link',{attrs:{"to":{
                            name: 'Detail',
                            params: { seoName: shoppingCartItem.seoName },
                          }}},[_vm._v("\n                          "+_vm._s(shoppingCartItem.productName)+"\n                        ")])],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(
                          _vm.substitute(
                            shoppingCartItem.orderLineItemID,
                            shoppingCartItem.allowSubstitute
                          )
                        ),expression:"\n                          substitute(\n                            shoppingCartItem.orderLineItemID,\n                            shoppingCartItem.allowSubstitute\n                          )\n                        "}],staticClass:"cart--labels q-mt-sm"},[_c('span',{staticClass:"allowed"},[_vm._v("\n                          Substitution Allowed\n                          "),_c('SvgIcon',{staticClass:"q-ml-sm",attrs:{"icon":"info-outline"}})],1)]),(shoppingCartItem.instruction)?_c('div',{staticClass:"cart--instruction text-body2 word-break-word q-mt-sm"},[_c('strong',{staticClass:"text-grey-6"},[_vm._v("Item instruction:")]),_c('br'),_vm._v("\n                        "+_vm._s(shoppingCartItem.instruction || '(not set)')+"\n                      ")]):_vm._e(),_c('div',{staticClass:"cart--quantity q-mt-sm"},[_vm._v(_vm._s(shoppingCartItem.qty)+" x\n                        "+_vm._s(_vm._f("currency")(shoppingCartItem.price)))])]),_c('div',{staticClass:"price"},[(
                          shoppingCartItem.totalPrice !=
                          shoppingCartItem.finalPrice
                        )?_c('span',{staticClass:"original-price",domProps:{"innerHTML":_vm._s(
                          _vm.$options.filters.currency(
                            shoppingCartItem.totalPrice
                          )
                        )}}):_vm._e(),_c('span',{class:shoppingCartItem.totalPrice !=
                          shoppingCartItem.finalPrice
                            ? 'discount-price'
                            : '',domProps:{"innerHTML":_vm._s(
                          _vm.$options.filters.currency(
                            shoppingCartItem.finalPrice
                          )
                        )}})])])])]),(
                  shoppingCartItem.linkedProducts &&
                  shoppingCartItem.linkedProducts.length > 0
                )?_c('div',{staticClass:"substitution-product--block"},_vm._l((shoppingCartItem.linkedProducts),function(linkedProduct,index){return _c('div',{key:`${linkedProduct.orderLineItemID}${index}`,staticClass:"substitution-product produt-data flex items-center"},[_c('div',{staticClass:"product-image"},[_c('q-avatar',{attrs:{"square":""}},[_c('box-image',{attrs:{"link":linkedProduct.isSellable
                            ? {
                                name: 'Detail',
                                params: { seoName: linkedProduct.seoName },
                              }
                            : '',"imageSrc":linkedProduct.image,"width":"40px"}})],1),_c('q-avatar',{attrs:{"flat":""}},[_c('SvgIcon',{attrs:{"icon":"linked"}})],1)],1),_c('div',{staticClass:"co-product-details"},[_c('div',{staticClass:"product--title"},[_c('h4',[(linkedProduct.isSellable)?_c('router-link',{attrs:{"to":{
                            name: 'Detail',
                            params: { seoName: linkedProduct.seoName },
                          }},domProps:{"innerHTML":_vm._s(linkedProduct.productName)}}):[_vm._v("\n                          "+_vm._s(linkedProduct.productName)+"\n                        ")],_c('q-tooltip',{attrs:{"max-width":"180px","content-class":"bg-dark"}},[_vm._v("\n                          "+_vm._s(linkedProduct.productName)+"\n                        ")])],2)]),_c('div',{staticClass:"price"},[(
                          linkedProduct.totalPrice != linkedProduct.finalPrice
                        )?_c('span',{staticClass:"original-price",domProps:{"innerHTML":_vm._s(
                          _vm.$options.filters.currency(linkedProduct.totalPrice)
                        )}}):_vm._e(),_c('span',{class:linkedProduct.totalPrice != linkedProduct.finalPrice
                            ? 'discount-price'
                            : '',domProps:{"innerHTML":_vm._s(
                          _vm.$options.filters.currency(
                            linkedProduct.finalPrice ||
                              linkedProduct.totalPrice
                          )
                        )}})]),_c('div',{staticClass:"flex product-edit--options"},[_c('div',{staticClass:"product-quantity"},[_c('span',{domProps:{"innerHTML":_vm._s(
                            `${
                              linkedProduct.qty
                            } X ${_vm.$options.filters.currency(
                              linkedProduct.price
                            )}`
                          )}})])])])])}),0):_vm._e()])])})],2)}),0)])],1),_c('StepperNavigationBtn',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isViewMode),expression:"!isViewMode"}],attrs:{"loading":_vm.loading,"next":_vm.updateSubstitution,"label":_vm.stepperNavigationBtnLabel,"isReviewOrder":_vm.isReviewOrder,"cancelAction":_vm.cancelEdit}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }