<template>
  <div>
    <div :class="isMobile ? 'checkoutHeightxs' : ''">
      <div class="row">
        <div class="col-xs-12 col-md-12" v-show="isMobile">
          <h2 class="stepsTitle" v-html="title" />
        </div>

        <div class="col-xs-12 col-md-12">
          <p class="q-px-sm line-height">
            Verify your age before placing an order as your cart contains age
            restricted items.
          </p>
          <p class="text-bold q-py-sm" v-show="isOrderVerified">
            <SvgIcon color="positive" icon="verify-success" size="20px" />
            Your age is verified successfully.
          </p>
        </div>
      </div>

      <!-- !isOrderVerified || !isViewMode -->
      <StepperNavigationBtn
        v-show="!isViewMode"
        :label="stepperNavigationBtnLabel"
        :loading="loading"
        :isReviewOrder="false"
        :next="verifyAge"
      />
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import StepperNavigationBtn from './StepperNavigationBtn'
import { hideShowFullSpinner } from 'src/utils'
import IdentityMixin from 'src/utils/IdentityMixin'

export default {
  name: 'VerifyAge',
  mixins: [IdentityMixin],
  components: {
    StepperNavigationBtn,
  },
  props: {
    next: {
      type: Function,
      default: () => {},
    },
    isReviewOrder: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    fulfillmentObject: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },
  data() {
    return {
      isViewMode: this.isReviewOrder,
      loading: false,
    }
  },
  computed: {
    ...mapGetters('persisted', [
      'eaivPersistedSessionId',
      'eaivPersistedVerificationSuccess',
    ]),
    ...mapGetters('guest', ['guestUser']),
    ...mapGetters('ageVerification', ['isOrderVerified']),
    ...mapGetters('address', ['getAddressByCustomerAddressID']),
    stepperNavigationBtnLabel() {
      if (!this.isOrderVerified) {
        return 'Verify Age'
      }
      return this.isReviewOrder ? 'Continue' : ''
    },
    getRedirectUri() {
      let uri = ''
      if (this.isCordova) {
        uri = process.env.ANDROID_DEEP_LINK
      } else {
        uri = Object.keys(this.$route.query).length
          ? window.location.href
          : window.location.origin + window.location.pathname
      }
      return uri
    },
    getEAIVExternalURL() {
      return process.env.EAIV_ExternalURL
    },
  },
  methods: {
    async verifyAge() {
      if (!this.isOrderVerified) {
        this.triggerEAIVFlow()
      } else {
        /* if (
          !this.isLoggedIn &&
          this.fulfillmentObject?.codeName != 'sd' &&
          this.fulfillmentObject?.pickupPerson
        )
          this.$store.commit('cart/SET_PICKUP_INFO', {
            fulfillmentMethodID: this.fulfillmentObject.fulfillmentMethodID,
            pickupPerson: this.fulfillmentObject.pickupPerson,
          }) */

        if (this.isReviewOrder) {
          this.isViewMode = true
        } else {
          this.next()
        }
      }
    },
    triggerEAIVFlow() {
      if (!this.isLoggedIn) {
        // Fetch personalInfo and Prefill inputs
        if (
          this.eaivPersistedVerificationSuccess &&
          this.eaivPersistedSessionId
        ) {
          hideShowFullSpinner(true)
          this.$store
            .dispatch(
              'ageVerification/checkStatus',
              this.eaivPersistedSessionId
            )
            .then((response) => {
              if (response.success && response.data) {
                if (
                  response.data?.isVerified === true &&
                  response.data?.personalInfo
                ) {
                  const personalInfo = response.data.personalInfo
                  let dob = personalInfo.dob
                    ? this.$options.filters.formatDate(
                        personalInfo.dob.split('T')[0],
                        'YYYY-MM-DD'
                      )
                    : ''

                  let reqData = {
                    collectPersonalInfo: false,
                    personalInfo: { ...personalInfo, dob },
                    redirectUri: this.getRedirectUri,
                    verificationDuring: 1,
                    customerID: null,
                  }

                  if (this.guestUser?.customerID) {
                    reqData.customerID = this.guestUser.customerID
                    reqData.preFillInformation = {
                      firstName: this.guestUser.firstName || '',
                      lastName: this.guestUser.lastName || '',
                      phone: this.guestUser.phoneNumber || '',
                      email: this.guestUser.email || '',
                      countryCode: this.guestUser.countryCode || '+1',
                      dob: this.guestUser.dob
                        ? this.$options.filters.formatDate(
                            this.guestUser.dob.split('T')[0],
                            'YYYY-MM-DD'
                          )
                        : undefined,
                    }
                  }

                  this.generateSession(reqData, !!!this.isCordova)
                } else {
                  let reqData = {
                    collectPersonalInfo: true,
                    redirectUri: this.getRedirectUri,
                    verificationDuring: 1,
                    customerID: null,
                  }

                  if (this.guestUser?.customerID) {
                    reqData.customerID = this.guestUser.customerID
                    reqData.preFillInformation = {
                      firstName: this.guestUser.firstName || '',
                      lastName: this.guestUser.lastName || '',
                      phone: this.guestUser.phoneNumber || '',
                      email: this.guestUser.email || '',
                      countryCode: this.guestUser.countryCode || '+1',
                      dob: this.guestUser.dob
                        ? this.$options.filters.formatDate(
                            this.guestUser.dob.split('T')[0],
                            'YYYY-MM-DD'
                          )
                        : undefined,
                    }
                  }

                  this.generateSession(reqData, !!!this.isCordova)
                }
              }
            })
            .finally(() => hideShowFullSpinner(false))
        } else {
          let reqData = {
            collectPersonalInfo: true,
            redirectUri: this.getRedirectUri,
            verificationDuring: 1,
            customerID: null,
          }

          if (this.guestUser?.customerID) {
            reqData.customerID = this.guestUser.customerID
            reqData.preFillInformation = {
              firstName: this.guestUser.firstName || '',
              lastName: this.guestUser.lastName || '',
              phone: this.guestUser.phoneNumber || '',
              email: this.guestUser.email || '',
              countryCode: this.guestUser.countryCode || '+1',
              dob: this.guestUser.dob
                ? this.$options.filters.formatDate(
                    this.guestUser.dob.split('T')[0],
                    'YYYY-MM-DD'
                  )
                : undefined,
            }
          }

          this.generateSession(reqData, !!!this.isCordova)
        }
      } else {
        let reqData = {
          redirectUri: this.getRedirectUri,
          verificationDuring: 1,
          customerID: this.customer.customerID || null,
        }
        if (this.customer?.isVerifiedOnline) {
          reqData.collectPersonalInfo = false
          reqData.personalInfo = this.personalInfoObj()
        } else {
          reqData.collectPersonalInfo = true
          reqData.preFillInformation = this.personalInfoObj()
        }

        this.generateSession(reqData, !!!this.isCordova)
      }
    },
    generateSession(reqData, isPopup = false) {
      this.loading = true
      this.$store
        .dispatch('ageVerification/sendPersonalInfo', reqData)
        .then((response) => {
          if (response.success && response.data && response.data?.sessionId) {
            if (isPopup || (!this.isCordova && 'FTXIdentityLite' in window)) {
              let payloadObj = {
                sessionId: response.data.sessionId,
                onSuccess: this.identitySuccessHandler,
                onError: this.identityErrorHandler,
                styleOptions: {
                  height: `calc(100dvh - ${this.getHeaderHeight}px)`,
                  top: `${this.getHeaderHeight}px`,
                },
              }
              this.initEAIVPopup(payloadObj)
            } else if (this.isCordova) {
              this.openURL(
                this.getEAIVExternalURL +
                  `?sessionId=${response.data.sessionId}`
              )
            }
          } else if (!response.success && response.message) {
            this.showError(response.message)
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    openURL(url) {
      if (this.isCordova) {
        window.open(url, '_system')
      } else {
        window.location = url
      }
    },
    personalInfoObj() {
      let address = null,
        dob = '',
        personalInfo = {}
      if (this.isLoggedIn) {
        address = this.getAddressByCustomerAddressID(
          this.customer.defaultAddressID
        )

        dob = this.currentUser.dob
          ? this.$options.filters.formatDate(this.currentUser.dob, 'YYYY-MM-DD')
          : ''
        personalInfo = {
          firstName: this.currentUser.firstName || '',
          lastName: this.currentUser.lastName || '',
          dob: dob,
          addressLine1: address?.line1 || '',
          addressLine2: address?.line2 || '',
          city: address?.city || '',
          state: address?.state || '',
          zip: address?.zip || '',
          phone: this.currentUser.phoneNumber || '',
          email: this.currentUser.email || '',
          countryCode: this.currentUser.countryCode || '+1',
        }
      }
      return personalInfo
    },
  },
}
</script>

<style lang="scss" scoped>
p.line-height {
  line-height: 1.3;
}
</style>
