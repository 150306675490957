<template>
  <div :class="isMobile ? 'checkoutHeightxs' : ''">
    <a
      href="javascript:void(0)"
      :class="['text-tertiary step-edit', { hidden: !isViewMode }]"
      @click="editInfo()"
    >
      <SvgIcon icon="edit" class="q-mr-sm" />Edit
    </a>
    <template v-if="!isViewMode">
      <q-form @submit="submitFormAndContinue">
        <div
          class="row q-col-gutter-md q-col-gutter-md-lg orderReviewInfo q-mt-none"
        >
          <div class="col-xs-12 col-sm-6 col-md-4">
            <h5 :class="isMobile ? 'text-primary' : ''">Shipping Fee</h5>
            <q-list class="shipping-method-list">
              <q-item
                tag="label"
                class="q-pl-none"
                v-for="rate of displayRates"
                :key="rate.rateID"
              >
                <q-item-section avatar>
                  <q-radio
                    v-model="shippingMethod"
                    :val="rate.rateID"
                    color="primary"
                  />
                </q-item-section>
                <q-item-section>
                  <q-item-label class="text-weight-bold">
                    {{ rate.rateName }}:
                    <strong class="text-primary" v-if="rate.finalAmount > 0">
                      {{ $options.filters.currency(rate.finalAmount) }}
                    </strong>
                    <strong class="text-primary" v-else>Free </strong>
                  </q-item-label>
                  <!-- <div class="text-body2 q-mt-sm">Delivery Tue, 12/13</div> -->
                </q-item-section>
              </q-item>
            </q-list>
            <q-btn
              v-show="shippingRatesKeys.length > itemToDisplay"
              unelevated
              color="primary"
              dense
              no-caps
              outline
              rounded
              size="12px"
              class="q-px-sm q-mt-sm"
              @click="openShippingRateDialog"
            >
              See More
            </q-btn>
          </div>
          <div
            class="col-xs-12 col-sm-6"
            :class="
              fulfillmentObject.codeName === 'sd' ? 'col-md-8' : 'col-md-6'
            "
          >
            <h5 :class="isMobile ? 'text-primary' : ''">
              Order Instructions
              <!-- <q-btn round unelevated size="sm" dense icon="edit"></q-btn> -->
            </h5>
            <q-input
              outlined
              type="textarea"
              rows="4"
              v-model="deliveryInstruction"
              maxlength="255"
            /><span class="float-right text-caption"
              >{{ deliveryInstruction?.length || 0 }}/255</span
            >
            <!-- <div class="text-caption"
              >Please note that Delivery Instructions are not a guarantee</div
            > -->
          </div>
        </div>
        <StepperNavigationBtn
          :label="stepperNavigationBtnLabel"
          :isReviewOrder="isReviewOrder"
          :cancelAction="cancelEdit"
          :class="isReviewOrder ? `justify-end q-py-xs` : ''"
        />
      </q-form>
    </template>
    <div v-else>
      <div class="row q-col-gutter-lg orderReviewInfo">
        <div class="col-xs-12 col-sm-6 col-md-4">
          <h5 :class="isMobile ? 'text-primary' : ''">Shipping Fee</h5>
          <q-list class="shipping-method-list">
            <q-item
              tag="label"
              class="q-pl-none"
              v-for="rate of displayRates"
              :key="rate.rateID"
            >
              <q-item-section avatar>
                <q-radio
                  v-model="shippingMethod"
                  :val="rate.rateID"
                  color="primary"
                />
              </q-item-section>
              <q-item-section>
                <q-item-label class="text-weight-bold">
                  {{ rate.rateName }}:
                  <strong class="text-primary" v-if="rate.finalAmount > 0">
                    {{ $options.filters.currency(rate.finalAmount) }}
                  </strong>
                  <strong class="text-primary" v-else>Free </strong>
                </q-item-label>
                <!-- <div class="text-body2 q-mt-sm">Delivery Tue, 12/13</div> -->
              </q-item-section>
            </q-item>
          </q-list>
          <q-btn
            v-show="shippingRatesKeys.length > itemToDisplay"
            unelevated
            color="primary"
            dense
            no-caps
            outline
            rounded
            size="12px"
            class="q-px-sm q-mt-sm"
            @click="openShippingRateDialog"
          >
            See More
          </q-btn>
        </div>
        <div class="col-xs-12 col-sm-6 col-sm-6">
          <h5 :class="isMobile ? 'text-primary' : ''"> Order Instructions </h5>
          <!-- <p>instruction value</p> -->
          <div class="text-grey q-mt-sm word-break-word">
            {{ persistedShippingInfo?.deliveryInstruction || '(not set)' }}
          </div>
        </div>
      </div>
    </div>
    <ShippingRates />
  </div>
</template>

<script>
import StepperNavigationBtn from './StepperNavigationBtn'
import { mapGetters } from 'vuex'
import ShippingRates from './ShippingRates'

export default {
  name: 'ShippingMethod',
  components: { StepperNavigationBtn, ShippingRates },
  props: {
    next: {
      type: Function,
      default: () => {},
    },
    isReviewOrder: {
      type: Boolean,
      default: false,
    },
    cartNumber: {
      type: String,
      default: '',
    },
    fulfillmentObject: {
      type: Object,
      default: () => {
        return {}
      },
    },
    title: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      shippingMethodDialog: false,
      localShippingMethod: null,
      deliveryInstruction: '',
      isViewMode: this.isReviewOrder,
      // fulfillmentMethodID: this.fulfillmentObject?.fulfillmentMethodID,
      selectedRate: null,
      itemToDisplay: 3,
    }
  },
  methods: {
    submitFormAndContinue() {
      this.$store.commit('cart/SET_CHECKOUT_SELECTION', {
        rateID: this.shippingMethod,
      })

      this.$store.commit('persistedLocal/SET_CART_PERSISTED_INFO', {
        cartNumber: this.cartNumber,
        data: {
          deliveryInstruction: this.deliveryInstruction,
        },
      })

      // this.$store.dispatch('cart/getCartDetail')

      if (this.isReviewOrder) {
        this.isViewMode = true
      } else {
        this.next()
      }
    },
    openShippingRateDialog() {
      this.changeDialogState({
        dialog: 'ShippingRates',
        val: true,
        properties: {
          cartNumber: this.cartNumber,
          isViewMode: this.isViewMode,
          setLocalShippingRate: this.setLocalShippingRate,
          selectedRate: this.shippingMethod,
        },
      })
    },
    cancelEdit() {
      this.isViewMode = true
      this.deliveryInstruction =
        this.persistedShippingInfo?.deliveryInstruction || ''
      this.localShippingMethod = ''
    },
    editInfo() {
      this.deliveryInstruction =
        this.persistedShippingInfo?.deliveryInstruction || ''
      this.isViewMode = false
    },
    setLocalShippingRate(rateID) {
      this.localShippingMethod = rateID
    },
  },
  computed: {
    ...mapGetters('persistedLocal', [
      'getCartPersistedInfo',
      'selectedAddressForFulfillmentCode',
    ]),
    ...mapGetters('cart', ['shippingRates', 'checkoutSelection']),
    stepperNavigationBtnLabel() {
      return this.isReviewOrder ? 'Apply' : ''
    },
    selectedAddress() {
      return this.selectedAddressForFulfillmentCode(
        this.fulfillmentObject.codeName
      )
    },
    persistedShippingInfo() {
      return this.getCartPersistedInfo(this.cartNumber)
    },
    shippingMethod: {
      get() {
        // if (!this.isViewMode && this.localShippingMethod)
        //   return this.localShippingMethod

        if (this.shippingRates[this.checkoutSelection?.rateID])
          return this.checkoutSelection.rateID

        // if (this.shippingRatesKeys.length) return this.shippingRatesKeys[0]

        return this.fulfillmentObject.shippingRateID || ''
      },
      set(val) {
        this.$store.commit('cart/SET_CHECKOUT_SELECTION', {
          rateID: val,
        })

        this.$store.dispatch('cart/getCartDetail', {
          isCheckoutPage: true,
        })

        // if (this.isViewMode) {
        // this.$store.commit('persisted/SET_CART_PERSISTED_INFO', {
        //   cartNumber: this.cartNumber,
        //   data: {
        //     rateID: val,
        //   },
        // })
        // } else {
        //   this.localShippingMethod = val
        // }
      },
    },
    shippingRatesKeys() {
      return Object.keys(this.shippingRates)
    },
    displayRates() {
      let displayList = {}
      let count = 1

      if (this.shippingRatesKeys.length > this.itemToDisplay) {
        for (const key in this.shippingRates) {
          if (
            this.itemToDisplay == count &&
            this.shippingMethod &&
            !displayList[this.shippingMethod]
          ) {
            displayList[this.shippingMethod] =
              this.shippingRates[this.shippingMethod]
          } else displayList[key] = this.shippingRates[key]

          if (count == this.itemToDisplay) break
          count++
        }
      } else displayList = this.shippingRates

      return displayList
    },
  },
  mounted() {
    this.deliveryInstruction =
      this.persistedShippingInfo?.deliveryInstruction || ''
  },
}
</script>
<style lang="scss" scoped>
@media (max-width: 1023px) {
  .shipping-method-list {
    .q-item {
      border: 1px solid #cccccc;
      border-radius: 7px;
      & + .q-item {
        margin-top: 10px;
      }
    }
  }
}
</style>
