<template>
  <q-stepper-navigation class="row q-col-gutter-x-sm">
    <div
      v-if="isReviewOrder"
      :class="
        isReviewOrder ? 'col-6 col-sm-3 col-md-2' : 'col-12 col-sm-3 col-md-2'
      "
    >
      <div class="form-action">
        <q-btn
          v-if="isReviewOrder"
          unelevated
          no-caps
          outline
          :rounded="!isMobile"
          @click="cancelAction"
          color="tertiary"
          label="Cancel"
          padding="sm"
          size="1.1rem"
          class="full-width"
          type="button"
        />
      </div>
    </div>
    <div
      :class="
        isReviewOrder ? 'col-6 col-sm-3 col-md-2' : 'col-12 col-sm-3 col-md-3'
      "
    >
      <div class="form-action">
        <q-btn
          :disable="disable"
          unelevated
          no-caps
          :rounded="!isMobile"
          @click="next"
          color="secondaryOnBody"
          :label="label ? label : 'Continue'"
          padding="sm"
          size="1.1rem"
          class="full-width"
          :loading="loading"
          type="submit"
        />
      </div>
    </div>
  </q-stepper-navigation>
</template>
<script>
export default {
  name: 'StepperNavigationBtn',
  props: {
    next: {
      type: Function,
      default: () => {},
    },
    label: {
      type: String,
      default: 'Continue',
    },
    disable: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    isReviewOrder: {
      type: Boolean,
      default: false,
    },
    cancelAction: {
      type: Function,
      default: () => {},
    },
  },
}
</script>
