<template>
  <div>
    <div :class="isMobile ? 'checkoutHeightxs' : ''">
      <a
        href="javascript:void(0)"
        :class="['text-tertiary step-edit', { hidden: !isViewMode }]"
        @click="editPaymentList()"
      >
        <SvgIcon icon="edit" class="q-mr-sm" />Edit
      </a>
      <h2 class="stepsTitle" v-show="isMobile" v-html="title" />
      <div class="orderReviewInfo">
        <div class="row">
          <p class="text-tertiary" style="color: #314154 !important">
            <strong
              v-if="
                !isViewMode ||
                (currentPaymentMethodId !== 'PAY_AT_STORE' && !isViewMode)
              "
            >
              Credit or debit card
              <!-- <q-icon name="error_outline" size="1rem" /> -->
            </strong>

            <!-- <strong
            v-else-if="currentPaymentMethodId == 'PAY_AT_STORE' && isViewMode"
          >
            Pay at Store
          </strong> -->
          </p>
          <div class="col-xs-12" v-show="!isViewMode">
            <div class="stepsAddress">
              <q-list>
                <q-item
                  v-for="(paymentMethod, index) of paymentMethodsList"
                  :key="index"
                  tag="label"
                >
                  <q-item-section
                    v-if="index == 'add-payment'"
                    class="addAddress"
                    @click="addPaymentMethod"
                  >
                    <q-item-label>
                      <span>+</span>
                      Add new credit/debit card
                    </q-item-label>
                  </q-item-section>
                  <q-item-section
                    class="relative-position"
                    :class="[{ invalidcard: paymentMethod.isInvalid }]"
                    v-else
                  >
                    <span
                      v-if="isMobile && paymentMethod.isInvalid"
                      class="text-body2 text-negative q-px-sm"
                      >This card is invalid as the retailer has changed the
                      payment method.</span
                    >
                    <q-btn
                      v-else-if="paymentMethod.isInvalid"
                      round
                      dense
                      padding="0"
                      flat
                      color="negative"
                      icon="info"
                      class="absolute-top-right q-mt-sm q-mr-sm"
                    >
                      <q-tooltip
                        content-class="bg-negative text-body2"
                        anchor="center left"
                        self="center right"
                        :offset="[10, 10]"
                      >
                        This card is invalid as <br />the retailer has
                        changed<br />
                        the payment method.
                      </q-tooltip>
                    </q-btn>
                    <q-radio
                      color="primaryOnBody"
                      @input="isMobile ? updateCartPaymentMethod() : null"
                      size="sm"
                      v-model="currentPaymentMethodId"
                      :val="paymentMethod.paymentMethodId"
                      :label="
                        currentPaymentMethodId == paymentMethod.paymentMethodId
                          ? 'Selected'
                          : 'Select'
                      "
                    />
                    <q-item-label>
                      <strong>{{ paymentMethod.cardHolderName }}</strong>
                      <span v-if="paymentMethod.isDefault" class="default">
                        Default
                      </span>
                    </q-item-label>
                    <q-item-label>
                      Ending in
                      <strong>{{ paymentMethod.cardLast4Digits }}</strong>
                    </q-item-label>
                    <q-item-label>
                      Expires <strong>{{ paymentMethod.cardExpiry }}</strong>
                      <span :class="`paymentIcon`">
                        <img
                          :src="`images/payment/${
                            getCardType(paymentMethod.cardType).logo
                          }`"
                          :title="getCardType(paymentMethod.cardType).title"
                        />
                      </span>
                    </q-item-label>
                    <q-separator class="q-mx-sm q-my-md" />
                    <q-item-label class="relative-position">
                      <strong>Billing Address</strong>
                      <p class="ellipsis text-weight-bold q-pt-md">
                        {{ paymentMethod?.address?.firstName }}
                        {{ paymentMethod?.address?.lastName }}
                        <q-tooltip
                          v-if="!isMobile"
                          max-width="180px"
                          content-class="bg-dark"
                        >
                          {{ paymentMethod?.address?.firstName }}
                          {{ paymentMethod?.address?.lastName }}
                        </q-tooltip>
                      </p>
                      <p
                        v-html="
                          $options.filters.formatAddress(paymentMethod?.address)
                        "
                        class="address"
                      >
                      </p>
                      <q-btn
                        unelevated
                        round
                        dense
                        color="grey-6"
                        text-color="white"
                        padding="6px"
                        :class="{
                          disable: paymentMethod.isInvalid,
                        }"
                        class="absolute-top-right q-mr-sm"
                        @click="editBillingInfo(paymentMethod)"
                      >
                        <q-icon name="edit" size="1rem" />
                      </q-btn>
                    </q-item-label>

                    <!-- <a href="javascript:void(0);" class="text-tertiary text-edit">
                    Edit
                  </a> -->
                  </q-item-section>
                </q-item>
              </q-list>
              <template
                v-if="
                  cartHasFulfillment(currentCartDetails, 'sp') &&
                  allowPayAtStoreForFulfillment('sp')
                "
              >
                <div class="col-xs-12 flex items-center q-my-md">
                  <div class="col"><q-separator /></div>
                  <div class="q-px-md"> OR</div>
                  <div class="col"><q-separator /></div>
                </div>

                <div class="col-xs-12">
                  <div class="stepsAddress">
                    <q-banner class="bg-orange-1" dense rounded>
                      Pay at Store available only for In Store and Curbside
                      Pickup
                    </q-banner>
                    <q-list>
                      <q-item
                        tag="label"
                        v-if="
                          cartHasFulfillment(currentCartDetails, 'scd') ||
                          cartHasFulfillment(currentCartDetails, 'sd')
                        "
                      >
                        <q-item-section>
                          <!-- <q-radio
                          v-model="payAtStore"
                          val="PAY_AT_STORE"
                          color="primaryOnBody"
                          size="sm"
                          :label="
                            payAtStore == 'PAY_AT_STORE' ? 'Selected' : 'Select'
                          "
                          @input="isMobile ? updateCartPaymentMethod() : null"
                        /> -->
                          <q-checkbox
                            v-model="payAtStore"
                            :value="payAtStore"
                            color="primaryOnBody"
                            size="sm"
                            :label="payAtStore == true ? 'Selected' : 'Select'"
                            @input="isMobile ? updateCartPaymentMethod() : null"
                          />
                          <q-item-label>
                            <SvgIcon
                              :size="isMobile ? '24px' : '44px'"
                              icon="pay-at-store"
                              class="q-mr-sm"
                            />
                            <strong class="">Pay at store</strong>
                          </q-item-label>
                          <q-item-label> Cash Or Card </q-item-label>
                          <q-item-label> Payment Due on Pickup </q-item-label>
                        </q-item-section>
                      </q-item>
                      <q-item tag="label" v-else>
                        <q-item-section>
                          <q-radio
                            v-model="currentPaymentMethodId"
                            val="PAY_AT_STORE"
                            color="primaryOnBody"
                            size="sm"
                            :label="
                              currentPaymentMethodId == 'PAY_AT_STORE'
                                ? 'Selected'
                                : 'Select'
                            "
                            @input="isMobile ? updateCartPaymentMethod() : null"
                          />
                          <q-item-label>
                            <SvgIcon
                              :size="isMobile ? '24px' : '44px'"
                              icon="pay-at-store"
                              class="q-mr-sm"
                            />
                            <strong class="">Pay at store</strong>
                          </q-item-label>
                          <q-item-label> Cash Or Card </q-item-label>
                          <q-item-label> Payment Due on Pickup </q-item-label>
                        </q-item-section>
                      </q-item>
                    </q-list>
                  </div>
                </div>
              </template>
            </div>
          </div>
          <div
            class="col-xs-12 q-mb-md"
            :class="
              selectedFulfillmentCodeName == 'sp'
                ? 'col-sm-6 col-md-4'
                : 'col-sm-6 col-md-4'
            "
            v-if="
              selectedPaymentMethod &&
              selectedPaymentMethod.cardLast4Digits &&
              currentPaymentMethodId !== 'PAY_AT_STORE'
            "
            v-show="isViewMode"
          >
            <h5>Credit or debit card</h5>
            <p>
              <span :class="`paymentIcon paymentIcon-left`"
                ><img
                  :src="`images/payment/${
                    getCardType(selectedPaymentMethod.cardType).logo
                  }`"
                  :title="getCardType(selectedPaymentMethod.cardType).title"
              /></span>
              {{ selectedPaymentMethod.cardHolderName }}
              <span
                v-if="selectedPaymentMethod.isDefault"
                class="default q-ml-sm"
              >
                Default </span
              ><br />
              Ending in: {{ selectedPaymentMethod.cardLast4Digits }} <br />
              Expires: {{ selectedPaymentMethod.cardExpiry }}
            </p>
          </div>
          <div
            class="col-xs-12 q-mb-md"
            :class="
              selectedFulfillmentCodeName == 'sp'
                ? 'col-sm-6 col-md-4'
                : 'col-sm-6 col-md-4'
            "
            v-if="
              selectedPaymentMethod &&
              selectedPaymentMethod.cardLast4Digits &&
              currentPaymentMethodId !== 'PAY_AT_STORE'
            "
            v-show="isViewMode"
          >
            <h5>Billing Address</h5>
            <p
              v-html="
                $options.filters.formatAddress(selectedPaymentMethod?.address)
              "
              class="address q-mb-sm"
            >
            </p>
          </div>
          <div
            class="col-xs-12 col-sm-6 col-md-4 q-mb-md"
            v-if="
              currentPaymentMethodId == 'PAY_AT_STORE' || payAtStore == true
            "
            v-show="isViewMode"
          >
            <!-- <SvgIcon size="44px" icon="pay-at-store" class="q-mr-sm" /> -->
            <h5>Pay at store </h5>
            <p>You have chosen Pay at store payment method.</p>
          </div>
        </div>
      </div>
    </div>
    <template>
      <StepperNavigationBtn
        :loading="loading"
        v-show="!isViewMode"
        :label="stepperNavigationBtnLabel"
        :isReviewOrder="isReviewOrder"
        :cancelAction="cancelEdit"
        :next="applyChanges"
        :disable="!currentPaymentMethodId"
      />
    </template>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import StepperNavigationBtn from './StepperNavigationBtn'
import { getClassForCardType } from 'src/utils'
import {
  mergeObjects,
  hideShowFullSpinner,
  cartHasFulfillment,
  getCardType,
} from 'src/utils'

export default {
  name: 'PaymentList',
  props: {
    isReviewOrder: {
      type: Boolean,
      default: false,
    },
    stepNo: {
      type: Number,
      default: 1,
    },
    next: {
      type: Function,
      default: () => {},
    },
    title: {
      type: String,
      default: '',
    },
  },
  components: { StepperNavigationBtn },
  data() {
    return {
      isViewMode: this.isReviewOrder,
      currentPaymentMethodId: null,
      payAtStore: false,
      loading: false,
    }
  },
  computed: {
    ...mapGetters('payment', ['paymentMethods']),
    ...mapGetters('cart', [
      'selectedPaymentMethodId',
      'selectedPaymentMethod',
      'currentCartNumber',
      'currentCartDetails',
      'isPayAtStoreAvailable',
    ]),
    ...mapGetters('locationDetail', ['allowPayAtStoreForFulfillment']),
    ...mapGetters('persisted', ['selectedFulfillmentCodeName']),
    paymentMethodsList() {
      if (this.isMobile)
        return mergeObjects({ 'add-payment': {} }, this.paymentMethods)

      return mergeObjects(this.paymentMethods, { 'add-payment': {} })
    },
    stepperNavigationBtnLabel() {
      return this.isReviewOrder ? 'Apply' : 'Review Order'
    },
  },
  methods: {
    getClassForCardType: getClassForCardType,
    cartHasFulfillment: cartHasFulfillment,
    getCardType: getCardType,
    addPaymentMethod() {
      let properties = {}
      if (!this.isLoggedIn)
        properties.customerID = this.currentCartDetails.customerID
      this.changeDialogState({
        dialog: 'AddPaymentMethod',
        val: true,
        properties,
      })
    },
    editBillingInfo(paymentMethod) {
      let properties = { paymentMethod, action: 'ChooseBillingAddress' }
      if (!this.isLoggedIn)
        properties.customerID = this.currentCartDetails.customerID
      this.changeDialogState({
        dialog: 'AddPaymentMethod',
        val: true,
        properties,
      })
    },
    cancelEdit() {
      if (
        cartHasFulfillment(this.currentCartDetails, 'sp') &&
        (cartHasFulfillment(this.currentCartDetails, 'scd') ||
          cartHasFulfillment(this.currentCartDetails, 'sd')) &&
        this.isPayAtStoreAvailable('sp')
      ) {
        this.currentPaymentMethodId = this.selectedPaymentMethodId
        this.payAtStore = true
      } else if (
        cartHasFulfillment(this.currentCartDetails, 'sp') &&
        !cartHasFulfillment(this.currentCartDetails, 'scd') &&
        !cartHasFulfillment(this.currentCartDetails, 'sd') &&
        this.isPayAtStoreAvailable('sp')
      ) {
        this.currentPaymentMethodId = 'PAY_AT_STORE'
        this.payAtStore = false
      } else {
        this.currentPaymentMethodId = this.selectedPaymentMethodId
        this.payAtStore = false
      }
      this.isViewMode = true
    },
    applyChanges() {
      this.setPayAtStoreOption()
      if (
        this.currentPaymentMethodId == 'PAY_AT_STORE' ||
        this.selectedPaymentMethodId == this.currentPaymentMethodId
      ) {
        this.isViewMode = true
        if (!this.isReviewOrder) {
          this.next()
        }
        return
      }
      this.loading = true

      let requestObj = {
        orderNumber: this.currentCartNumber,
        paymentMethodId: this.currentPaymentMethodId,
      }

      if (!this.isLoggedIn && this.currentCartDetails?.customerID)
        requestObj.customerID = this.currentCartDetails.customerID

      this.$store
        .dispatch('cart/updateCartPaymentMethod', requestObj)
        .then((response) => {
          if (response) {
            this.isViewMode = true
            if (!this.isReviewOrder) {
              this.next()
            }
          } else {
            alert('Unexpected error occured')
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    updateCartPaymentMethod() {
      this.setPayAtStoreOption()
      if (
        this.currentPaymentMethodId == 'PAY_AT_STORE' ||
        this.payAtStore == true ||
        this.selectedPaymentMethodId == this.currentPaymentMethodId ||
        !this.isMobile
      ) {
        return
      }

      let requestObj = {
        orderNumber: this.currentCartNumber,
        paymentMethodId: this.currentPaymentMethodId,
      }

      if (!this.isLoggedIn && this.currentCartDetails?.customerID)
        requestObj.customerID = this.currentCartDetails.customerID

      hideShowFullSpinner(true)
      this.$store
        .dispatch('cart/updateCartPaymentMethod', requestObj)
        .then((response) => {})
        .finally(() => {
          hideShowFullSpinner(false)
        })
    },
    editPaymentList() {
      // this.currentPaymentMethodId = this.selectedPaymentMethodId
      // this.isViewMode = false
      if (
        cartHasFulfillment(this.currentCartDetails, 'sp') &&
        this.isPayAtStoreAvailable('sp')
      ) {
        // if (this.payAtStore != 'PAY_AT_STORE')
        //   this.currentPaymentMethodId = 'PAY_AT_STORE'
        // this.payAtStore = 'PAY_AT_STORE'
      } else {
        this.currentPaymentMethodId = this.selectedPaymentMethodId
      }
      this.isViewMode = false
    },
    setPayAtStoreOption() {
      let isPayAtStoreAvailable = {
        sp: false,
        scd: false,
        sd: false,
      }

      if (
        cartHasFulfillment(this.currentCartDetails, 'sp') &&
        (this.currentPaymentMethodId == 'PAY_AT_STORE' ||
          this.payAtStore == true)
      )
        isPayAtStoreAvailable = Object.assign({}, isPayAtStoreAvailable, {
          sp: true,
        })
      this.$store.commit('cart/SET_PAY_AT_STORE', isPayAtStoreAvailable)
    },
  },
  created() {
    if (
      cartHasFulfillment(this.currentCartDetails, 'sp') &&
      (cartHasFulfillment(this.currentCartDetails, 'scd') ||
        cartHasFulfillment(this.currentCartDetails, 'sd')) &&
      this.isPayAtStoreAvailable('sp')
    ) {
      this.currentPaymentMethodId = this.selectedPaymentMethodId
      this.payAtStore = true
    } else if (
      cartHasFulfillment(this.currentCartDetails, 'sp') &&
      this.isPayAtStoreAvailable('sp')
    ) {
      this.currentPaymentMethodId = 'PAY_AT_STORE'
    } else {
      this.currentPaymentMethodId = this.selectedPaymentMethodId
    }

    this.$root.$on('payment-method-added', (data) => {
      if (data.newPaymentMethodId) {
        this.currentPaymentMethodId = data.newPaymentMethodId
        this.updateCartPaymentMethod()
      }
    })
  },
}
</script>
