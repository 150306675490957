import { render, staticRenderFns } from "./TipConfirmDialog.vue?vue&type=template&id=530c43bd&scoped=true"
import script from "./TipConfirmDialog.vue?vue&type=script&lang=js"
export * from "./TipConfirmDialog.vue?vue&type=script&lang=js"
import style0 from "./TipConfirmDialog.vue?vue&type=style&index=0&id=530c43bd&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "530c43bd",
  null
  
)

export default component.exports
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QSpace from 'quasar/src/components/space/QSpace.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import qInstall from "../../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(component, 'components', {QDialog,QCard,QCardSection,QSpace,QBtn});qInstall(component, 'directives', {ClosePopup});
