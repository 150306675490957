<template>
  <q-dialog
    v-model="dialogState"
    :position="isMobile ? 'bottom' : 'standard'"
    :transition-show="isMobile ? 'slide-up' : 'jump-up'"
    :transition-hide="isMobile ? 'slide-down' : 'jump-down'"
  >
    <q-card class="removeCartItem">
      <q-card-section class="row items-center q-pb-none">
        <q-space />
        <q-btn icon="close" flat round dense v-close-popup />
      </q-card-section>

      <q-card-section class="inner text-center">
        <h5>You forgot to add a tip</h5>
        <p>Do you want to add a tip and then place the order? </p>
        <p class="tip-confirm-dialog-actions">
          <q-btn
            unelevated
            rounded
            outline
            color="tertiary"
            no-caps
            label="No, Place Order"
            class="q-ma-sm q-py-sm q-px-md radius-lg"
            @click="placeOrder"
          />
          <q-btn
            unelevated
            rounded
            color="secondary"
            label="Yes, Add Tip"
            no-caps
            class="q-ma-sm q-py-sm q-px-md radius-lg"
            @click="openTipDialog"
          />
        </p>
      </q-card-section>
    </q-card>
  </q-dialog>
</template>
<script>
export default {
  name: 'TipConfirmDialog',
  data() {
    return {
      dialog: 'TipConfirmDialog',
    }
  },

  computed: {
    dialogState: {
      get() {
        return this.getDialogState(this.dialog)
      },
      set(val) {
        this.changeDialogState({
          dialog: this.dialog,
          val,
        })
      },
    },
  },
  methods: {
    openTipDialog() {
      this.changeDialogState({
        dialog: 'TipDialog',
        val: true,
      })
    },
    placeOrder() {
      this.changeDialogState({
        dialog: 'TipConfirmDialog',
        val: false,
      })

      this.$emit('checkoutOrder')
    },
  },
}
</script>
<style lang="scss" scoped>
.tip-confirm-dialog-actions {
  .q-btn {
    @media (max-width: 480px) {
      width: 100%;
    }
  }
}
</style>
