var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{class:_vm.isMobile ? 'checkoutHeightxs' : ''},[_c('a',{class:['text-tertiary step-edit', { hidden: !_vm.isViewMode }],attrs:{"href":"javascript:void(0)"},on:{"click":function($event){_vm.isViewMode = false}}},[_c('SvgIcon',{staticClass:"q-mr-sm",attrs:{"icon":"edit"}}),_vm._v("Edit\n    ")],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isViewMode),expression:"!isViewMode"}],staticClass:"row"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isMobile),expression:"isMobile"}],staticClass:"col-xs-12 col-md-12"},[_c('h2',{staticClass:"stepsTitle",domProps:{"innerHTML":_vm._s(_vm.title)}})]),_c('div',{class:['col-xs-12', { 'col-md-12 order-1': _vm.addressType == 'scd' }]},[_c('div',{staticClass:"stepsAddress"},[_c('q-list',_vm._l((_vm.addressList),function(address,index){return _c('q-item',{key:`${address.addressId} ${index}`,class:_vm.shippingAddress &&
                _vm.shippingAddress.addressId == address.addressId &&
                _vm.isAddressDisable(address)
                  ? 'active'
                  : '',attrs:{"tag":"label"}},[(index == 'add-address')?_c('q-item-section',{staticClass:"addAddress",on:{"click":function($event){return _vm.editAddress()}}},[_c('q-item-label',[_c('span',[_vm._v("+")]),_vm._v("\n                  Add new address\n                ")])],1):_c('q-item-section',[_c('div',{style:(!_vm.isAddressDisable(address) && index !== 'add-address'
                      ? 'opacity: 0.3; cursor: not-allowed !important;'
                      : '')},[_c('q-radio',{attrs:{"size":"sm","value":_vm.shippingAddressId,"val":address.addressId,"label":_vm.shippingAddress &&
                      _vm.shippingAddress.addressId == address.addressId
                        ? 'Selected'
                        : 'Select',"disable":!_vm.isAddressDisable(address)},on:{"input":function($event){return _vm.addressChanged(address)}}}),_c('q-item-label',[_vm._v("\n                    "+_vm._s(`${address.firstName} ${address.lastName}`)+"\n                    "),(address.isDefault)?_c('span',{staticClass:"default"},[_vm._v("\n                      Default\n                    ")]):_vm._e()]),_c('q-item-label',{staticClass:"address q-mb-sm",domProps:{"innerHTML":_vm._s(_vm.$options.filters.formatAddress(address))}}),(_vm.addressType == 'scd' && address.isDeliver === false)?_c('q-item-label',{staticClass:"text-red bg-red-1 dotted-border-red delivery-address-note text-caption q-pa-sm rounded-borders q-mx-sm"},[_vm._v("\n                    *Unable To Deliver to This Location\n                  ")]):(
                      _vm.addressType == 'sd' &&
                      address.isShippingPossible === false
                    )?_c('q-item-label',{staticClass:"'text-red bg-red-1 dotted-border-red delivery-address-note text-caption q-pa-sm rounded-borders q-mx-sm'"},[_vm._v("\n                    *Unable To ship to This Location\n                  ")]):_vm._e()],1),_c('a',{staticClass:"editAddress",attrs:{"href":"javascript:void(0);"},on:{"click":function($event){return _vm.editAddress(address)}}},[_c('q-icon',{attrs:{"name":"edit","size":"1rem"}})],1)])],1)}),1)],1)])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isViewMode),expression:"isViewMode"}],staticClass:"orderReviewInfo"},[_c('div',{staticClass:"row q-col-gutter-x-lg"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isMobile),expression:"isMobile"}],staticClass:"col-xs-12 col-md-12"},[_c('h2',{staticClass:"stepsTitle"},[_vm._v("Address Details")])]),(_vm.addressType == 'scd' && _vm.isSlotBookingRequired)?_c('div',{staticClass:"col-xs-12 col-sm-6 col-md-4 q-mb-md order-2"},[(_vm.isSlotBookingRequired)?_c('div',{staticClass:"stepsTimeSlot"},[_c('h5',[_vm._v("\n              Time Slot\n              "),_c('a',{staticClass:"text-tertiary",attrs:{"href":"javascript:void(0);"},on:{"click":function($event){return _vm.openReserSlot('SCD')}}},[_vm._v("\n                Edit\n              ")])]),(_vm.formatedCustomerSCDBookedSlot)?_c('p',{domProps:{"innerHTML":_vm._s(_vm.formatedCustomerSCDBookedSlot)}}):_vm._e()]):_vm._e()]):_vm._e(),(_vm.selectedAddress)?_c('div',{staticClass:"col-xs-12 col-sm-6 col-md-4 q-mb-md order-1"},[_c('h5',{domProps:{"textContent":_vm._s(
              _vm.addressType && _vm.addressType === 'scd'
                ? 'Delivery Address'
                : 'Shipping to'
            )}}),_c('p',[_vm._v("\n            "+_vm._s(_vm.selectedAddress.firstName + ' ' + _vm.selectedAddress.lastName)+"\n            "),(_vm.selectedAddress.isDefault)?_c('span',{staticClass:"default"},[_vm._v("\n              Default\n            ")]):_vm._e()]),_c('p',{domProps:{"innerHTML":_vm._s(_vm.$options.filters.formatAddress(_vm.selectedAddress))}}),(_vm.addressType == 'scd' && _vm.selectedAddress.isDeliver === false)?_c('q-item-label',{staticClass:"text-red delivery-address-note q-pt-sm"},[_vm._v("\n            *Unable To Deliver to This Location\n          ")]):(
              _vm.addressType == 'sd' &&
              _vm.selectedAddress.isShippingPossible === false
            )?_c('q-item-label',{staticClass:"text-red delivery-address-note q-pt-sm"},[_vm._v("\n            *Unable To ship to This Location\n          ")]):_vm._e()],1):_vm._e()])])]),_c('StepperNavigationBtn',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isViewMode),expression:"!isViewMode"}],attrs:{"next":_vm.applyOrContinue,"label":_vm.stepperNavigationBtnLabel,"loading":_vm.loading,"disable":!_vm.shippingAddress,"isReviewOrder":_vm.isReviewOrder,"cancelAction":_vm.cancelEdit}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }