<template>
  <ftx-dialog
    :dialog="dialog"
    class="q-px-none shipping-method-dialog"
    position="right"
    maximized
    :before-show="onShow"
    :onHide="onHide"
  >
    <template v-slot:DialogContent>
      <q-card class="shippingmethod-dialog">
        <q-card-section class="flex">
          <div class="text-h6 text-black">Shipping Fee</div>
          <q-btn
            icon="close"
            class="q-ml-auto"
            dense
            unelevated
            @click="closeDialog"
            rounded
          />
        </q-card-section>
        <q-separator />
        <q-card-section>
          <q-scroll-area
            class="shipping-method-height"
            :thumb-style="scrollAreaThumbStyle"
            :bar-style="scrollAreaBarStyle"
            :visible="scrollAreaVisible"
            style="padding-right: 15px"
          >
            <q-list>
              <q-item
                tag="label"
                v-for="(rate, id) of shippingRates"
                :key="`${rate.rateID}-${id}`"
              >
                <q-item-section avatar>
                  <q-radio
                    v-model="selectedRate"
                    :val="rate.rateID"
                    color="primary"
                  />
                </q-item-section>
                <q-item-section>
                  <q-item-label class="text-weight-bold">
                    {{ rate.rateName }}:
                    <strong class="text-primary" v-if="rate.finalAmount > 0">
                      {{ $options.filters.currency(rate.finalAmount) }}
                    </strong>
                    <strong class="text-primary" v-else>Free </strong>
                  </q-item-label>
                  <!-- <div class="text-body2 q-mt-sm">Delivery Tue, 12/13</div> -->
                </q-item-section>
              </q-item>
            </q-list>
          </q-scroll-area>
        </q-card-section>
        <q-separator />
        <q-card-section class="text-center form-action">
          <div class="row q-col-gutter-md">
            <div class="col">
              <q-btn
                outline
                rounded
                class="q-btn-lg full-width"
                no-caps
                @click="closeDialog"
                >Cancel</q-btn
              >
            </div>
            <div class="col">
              <q-btn
                rounded
                unelevated
                no-caps
                color="primary"
                class="q-btn-lg full-width"
                @click="saveRate"
              >
                Select
              </q-btn>
            </div>
          </div>
        </q-card-section>
      </q-card>
    </template>
  </ftx-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
function initialState() {
  return {
    dialog: 'ShippingRates',
    selectedRate: null,
  }
}
export default {
  name: 'ShippingRates',
  data: function () {
    return initialState()
  },
  computed: {
    ...mapGetters('cart', ['shippingRates']),
    properties() {
      return this.getDialogProperties(this.dialog)
    },
    dialogState: {
      get() {
        return this.getDialogState(this.dialog)
      },
      set(val) {
        this.changeDialogState({
          dialog: this.dialog,
          val,
        })
      },
    },
  },
  methods: {
    onShow() {
      this.selectedRate = this.properties.selectedRate
    },
    onHide() {
      Object.assign(this.$data, initialState())
    },
    closeDialog() {
      this.dialogState = false
    },
    async saveRate() {
      if (!this.selectedRate)
        return this.showError('Please select shipping rate.')

      // if (this.properties.isViewMode) {
      this.$store.commit('cart/SET_CHECKOUT_SELECTION', {
        rateID: this.selectedRate,
      })

      this.$store.dispatch('cart/getCartDetail', {
        isCheckoutPage: true,
      })

      // this.$store.commit('persisted/SET_CART_PERSISTED_INFO', {
      //   cartNumber: this.properties.cartNumber,
      //   data: {
      //     rateID: this.selectedRate,
      //   },
      // })
      // } else {
      //   this.properties.setLocalShippingRate(this.selectedRate)
      // }
      this.closeDialog()
    },
  },
}
</script>

<style lang="scss">
.shippingmethod-dialog,
.q-dialog__inner--maximized > div.shippingmethod-dialog {
  width: 100%;
  width: 400px;
  border-radius: 0px;
  .q-list {
    .q-item {
      border: 1px solid #cccccc;
      border-radius: 7px;
      & + .q-item {
        margin-top: 15px;
      }
    }
  }
  .shipping-method-height {
    min-height: calc(100dvh - 185px);
    height: calc(100dvh - 185px);
  }
}

.q-dialog__inner--fullheight.q-dialog__inner--maximized {
  padding: 0;
}
</style>
