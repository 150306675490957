<template>
  <div>
    <q-form
      ref="pickupInforForm"
      @submit="submitFormAndContinue"
      class="position-inherit"
    >
      <div :class="isMobile ? 'checkoutHeightxs' : ''">
        <a
          href="javascript:void(0)"
          :class="['text-tertiary step-edit', { hidden: !isViewMode }]"
          @click="editInfo()"
        >
          <SvgIcon icon="edit" class="q-mr-sm" />Edit
        </a>
        <template v-if="!isViewMode">
          <div class="row">
            <div class="col-xs-12 col-md-12" v-show="isMobile">
              <h2 class="stepsTitle" v-html="title" />
            </div>

            <div
              class="col-xs-12 col-md-4 order-2"
              v-if="fulfillmentObject.codeName == 'sp' || isSlotBookingRequired"
            >
              <div
                class="stepsTimeSlot q-mb-md"
                v-if="fulfillmentObject.codeName == 'sp'"
              >
                <h5>Pickup Method</h5>
                <q-list class="shipping-method-list">
                  <q-item dense tag="label" class="q-pa-sm r-8">
                    <q-item-section side>
                      <q-radio
                        dense
                        v-model="pickUpMethod"
                        :val="1"
                        color="primary"
                      />
                    </q-item-section>
                    <q-item-section>
                      <q-item-label class="text-weight-bold">
                        In Store Pickup
                      </q-item-label>
                    </q-item-section>
                  </q-item>
                  <q-item dense tag="label" class="q-pa-sm r-8">
                    <q-item-section side>
                      <q-radio
                        dense
                        v-model="pickUpMethod"
                        :val="2"
                        color="primary"
                      />
                    </q-item-section>
                    <q-item-section>
                      <q-item-label class="text-weight-bold">
                        Curbside Pickup
                      </q-item-label>
                    </q-item-section>
                  </q-item>
                </q-list>
              </div>
              <div
                class="stepsTimeSlot"
                v-if="
                  isSlotBookingRequired && fulfillmentObject.codeName == 'sp'
                "
              >
                <h5>
                  Time Slot
                  <a
                    href="javascript:void(0);"
                    class="text-tertiary"
                    @click="
                      openReserSlot(fulfillmentObject.codeName.toUpperCase())
                    "
                  >
                    Edit
                  </a>
                </h5>
                <p
                  v-if="
                    fulfillmentObject.codeName == 'sp' &&
                    formatedCustomerSPBookedSlot
                  "
                  v-html="formatedCustomerSPBookedSlot"
                />
                <!-- <p
              v-if="
                fulfillmentObject.codeName == 'is' &&
                formatedCustomerISBookedSlot
              "
              v-html="formatedCustomerISBookedSlot"
            /> -->
              </div>
            </div>

            <div
              :class="[
                'col-xs-12',
                {
                  'col-md-8 order-1': fulfillmentObject.codeName == 'sp',
                },
              ]"
            >
              <div class="orderPickupInfo">
                <p class="text-tertiary">
                  We'll email you when your order is ready.
                </p>
                <p
                  class="text-primaryOnBody"
                  v-show="currentCartDetails?.ageRequired > 0"
                >
                  Bring a valid government ID such as driver's license or
                  passport.
                </p>
              </div>
              <div class="cs-review-info">
                <div class="row q-col-gutter-x-lg">
                  <div class="col-xs-12 col-sm-6 col-md-4">
                    <div class="form-field">
                      <q-input
                        outlined
                        v-model.trim="pickUpByName1"
                        :label="
                          fulfillmentObject.codeName === 'scd'
                            ? 'Handover to Person'
                            : 'Pickup Person'
                        "
                        placeholder="Full Name"
                        dense
                        :rules="[
                          (val) => validateRequired(val),
                          this.validatePersonName(
                            pickUpByName1,
                            'Please enter a valid name'
                          ),
                        ]"
                        :lazy-rules="lazyValidationRules"
                      />
                    </div>
                  </div>
                  <div class="col-xs-12 col-sm-6 col-md-4">
                    <div class="form-field">
                      <q-input
                        outlined
                        v-model="pickUpByEmail1"
                        label="Email"
                        placeholder="Your Email"
                        dense
                        :rules="[
                          (val) => validateRequired(val),
                          this.validateEmail,
                        ]"
                        type="email"
                      />
                    </div>
                    <!-- <q-input
                  outlined
                  v-model="pickUpByPhone1"
                  label="Get Texted too (optional)"
                  placeholder="Your phone number"
                  v-mask="phoneMask"
                  :rules="[this.validatePhone()]"
                  dense
                /> -->
                  </div>
                  <!-- <div
                v-if="currentUserIdentity"
                class="col-xs-12 col-sm-12 col-md-12"
              >
                Email: {{ currentUserIdentity }}
              </div> -->
                  <div v-if="false" class="col-xs-12 col-sm-6 col-md-4">
                    <div class="form-field">
                      <q-input
                        outlined
                        dense
                        v-model="pickupByDOB1"
                        mask="date"
                        :rules="['date']"
                        label="Date of Birth (optional)"
                        placeholder="yyyy/mm/dd"
                      >
                        <template v-slot:append>
                          <q-icon name="event" class="cursor-pointer">
                            <q-popup-proxy
                              ref="qDateProxy"
                              transition-show="scale"
                              transition-hide="scale"
                            >
                              <q-date
                                v-model="pickupByDOB1"
                                :options="isDateAllowed"
                              >
                                <div class="row items-center justify-end">
                                  <q-btn
                                    v-close-popup
                                    label="Close"
                                    color="primary"
                                    flat
                                  />
                                </div>
                              </q-date>
                            </q-popup-proxy>
                          </q-icon>
                        </template>
                      </q-input>
                    </div>
                  </div>
                </div>
                <div class="row q-col-gutter-x-lg">
                  <div class="col-xs-12">
                    <div class="form-field">
                      <q-checkbox
                        v-model="adddAlternatePickup"
                        :label="
                          fulfillmentObject.codeName === 'scd'
                            ? 'Add an alternate person to handover the order (optional)'
                            : 'Add an alternate pickup person (optional)'
                        "
                      />
                    </div>
                  </div>
                </div>
                <div v-if="adddAlternatePickup" class="row q-col-gutter-x-lg">
                  <div class="col-xs-12 col-sm-6 col-md-4">
                    <div class="form-field">
                      <q-input
                        outlined
                        v-model.trim="pickUpByName2"
                        :label="
                          fulfillmentObject.codeName === 'scd'
                            ? 'Handover to Person'
                            : 'Pickup Person'
                        "
                        placeholder="Full Name"
                        dense
                        :rules="[
                          (val) => validateRequired(val),
                          this.validatePersonName(
                            pickUpByName2,
                            'Please enter a valid name'
                          ),
                        ]"
                        :lazy-rules="lazyValidationRules"
                      />
                    </div>
                  </div>
                  <div class="col-xs-12 col-sm-6 col-md-4">
                    <!-- <q-input
                  outlined
                  v-model="pickUpByPhone2"
                  label="Get Texted too (optional)"
                  placeholder="Your phone number"
                  v-mask="phoneMask"
                  :rules="[this.validatePhone()]"
                  dense
                /> -->
                    <div class="form-field">
                      <q-input
                        outlined
                        v-model="pickUpByEmail2"
                        label="Email (optional)"
                        placeholder="Your Email"
                        dense
                        :rules="[this.validateEmail]"
                        type="email"
                      />
                    </div>
                  </div>
                  <div v-if="false" class="col-xs-12 col-sm-6 col-md-4">
                    <div class="form-field">
                      <q-input
                        outlined
                        dense
                        v-model="pickupByDOB2"
                        mask="date"
                        :rules="['date']"
                        label="Date of Birth (optional)"
                        placeholder="yyyy/mm/dd"
                      >
                        <template v-slot:append>
                          <q-icon name="event" class="cursor-pointer">
                            <q-popup-proxy
                              ref="qDateProxy"
                              transition-show="scale"
                              transition-hide="scale"
                            >
                              <q-date
                                v-model="pickupByDOB1"
                                :options="isDateAllowed"
                              >
                                <div class="row items-center justify-end">
                                  <q-btn
                                    v-close-popup
                                    label="Close"
                                    color="primary"
                                    flat
                                  />
                                </div>
                              </q-date>
                            </q-popup-proxy>
                          </q-icon>
                        </template>
                      </q-input>
                    </div>
                  </div>
                </div>

                <div
                  class="row q-col-gutter-x-lg"
                  v-if="
                    fulfillmentObject &&
                    fulfillmentObject.deliveryMethod == 1 &&
                    !fulfillmentObject.isSignatureRequired &&
                    !fulfillmentObject.isAgeRestricted
                  "
                >
                  <div class="col-xs-12">
                    <q-checkbox
                      v-model="isContactlessdelivery"
                      label="Do you want contactless delivery?"
                    />
                  </div>
                </div>
                <div class="row q-col-gutter-x-lg">
                  <div class="col-12 col-sm-12 col-md-8">
                    <div class="stepsTimeSlot q-mt-md">
                      <h5 :class="isMobile ? 'text-primary' : ''">
                        Order Instructions
                        <!-- <q-btn round unelevated size="sm" dense icon="edit"></q-btn> -->
                      </h5>
                      <!-- <div class="text-caption"
              >Please note that Delivery Instructions are not a guarantee</div
            > -->
                    </div>
                    <div class="form-field__area pb-0">
                      <q-input
                        outlined
                        v-model="deliveryInstruction"
                        type="textarea"
                        color="grey"
                        label="Enter Instruction"
                        maxlength="255"
                      >
                      </q-input
                      ><span class="float-right text-caption"
                        >{{ deliveryInstruction?.length || 0 }}/255</span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
        <div class="orderReviewInfo" v-else>
          <h2 class="stepsTitle" v-show="isMobile">PickUp Information</h2>
          <div class="row q-col-gutter-x-lg">
            <div class="col-xs-12 col-sm-6 col-md-4">
              <h5>
                {{
                  fulfillmentObject.codeName === 'scd' ||
                  fulfillmentObject.codeName === 'sd'
                    ? 'Deliver To'
                    : 'Pickup Person'
                }}
              </h5>
              <p class="q-pb-md">
                Name: {{ pickUpByName1 }} <br />
                Email:
                {{ pickUpByEmail1 || 'NA' }}
                <!-- {{ maskPhoneNumber(pickUpByPhone1) }} -->
              </p>

              <!-- <p>
            <a href="javascript:void(0)" class="link-dark">
              + Get a email when your order is ready.
            </a>
          </p> -->

              <div v-if="adddAlternatePickup && pickUpByName2 != ''">
                <h5>Alternate Person</h5>
                <p class="q-pb-md">
                  Name: {{ pickUpByName2 }}<br />
                  <!-- Phone Number:
              {{ maskPhoneNumber(pickUpByPhone2) || 'NA' }} -->
                  Email: {{ pickUpByEmail2 || 'NA' }}
                </p>
                <!-- <p>
            <a href="javascript:void(0)" class="link-dark">
              + Get a email when your order is ready.
            </a>
          </p> -->
              </div>
              <div
                v-if="
                  fulfillmentObject &&
                  fulfillmentObject.deliveryMethod == 1 &&
                  !fulfillmentObject.isSignatureRequired &&
                  !fulfillmentObject.isAgeRestricted
                "
              >
                <div class="col">
                  <q-checkbox
                    dense
                    v-model="isContactlessdelivery"
                    label="Do you want contactless delivery?"
                  />
                </div>
              </div>
            </div>
            <!-- <div
          v-if="adddAlternatePickup && pickUpByName2 != ''"
          class="col-xs-12 col-sm-6 col-md-4"
        >
          <h5>Alternate Person</h5>
          <p class="q-pb-md">
            Name: {{ pickUpByName2 }}<br />Phone Number:
            {{ maskPhoneNumber(pickUpByPhone2) || 'NA' }}
            {{ pickUpByEmail2 || 'NA' }}
          </p>
          <p>
            <a href="javascript:void(0)" class="link-dark">
              + Get a email when your order is ready.
            </a>
          </p>
        </div> -->
            <div
              class="col-xs-12 col-sm-6 col-md-4"
              v-if="fulfillmentObject.codeName == 'sp'"
            >
              <h5>Pickup Method</h5>
              <q-list class="shipping-method-list">
                <q-item dense tag="label" class="q-pa-sm r-8">
                  <q-item-section side>
                    <q-radio
                      dense
                      v-model="pickUpMethod"
                      :val="1"
                      color="primary"
                    />
                  </q-item-section>
                  <q-item-section>
                    <q-item-label class="text-weight-bold">
                      In Store Pickup
                    </q-item-label>
                  </q-item-section>
                </q-item>
                <q-item dense tag="label" class="q-pa-sm r-8">
                  <q-item-section side>
                    <q-radio
                      dense
                      v-model="pickUpMethod"
                      :val="2"
                      color="primary"
                    />
                  </q-item-section>
                  <q-item-section>
                    <q-item-label class="text-weight-bold">
                      Curbside Pickup
                    </q-item-label>
                  </q-item-section>
                </q-item>
              </q-list>
            </div>
            <div
              v-if="isSlotBookingRequired && fulfillmentObject.codeName == 'sp'"
              class="col-xs-12 col-sm-6 col-md-4"
            >
              <div class="stepsTimeSlot">
                <h5>
                  Time Slot
                  <a
                    href="javascript:void(0);"
                    class="text-tertiary"
                    @click="
                      openReserSlot(fulfillmentObject.codeName.toUpperCase())
                    "
                  >
                    Edit
                  </a>
                </h5>
                <p
                  v-if="formatedCustomerSPBookedSlot"
                  v-html="formatedCustomerSPBookedSlot"
                />
              </div>
            </div>
            <div
              class="col-xs-12 col-sm-6"
              :class="
                this.selectedFulfillmentCodeName == 'sp'
                  ? 'col-md-12'
                  : this.selectedFulfillmentCodeName == 'scd'
                  ? 'col-md-8'
                  : 'col-md-6'
              "
            >
              <div class="stepsTimeSlot">
                <h5 :class="isMobile ? 'text-primary' : ''">
                  Order Instructions
                </h5>
                <!-- <p>instruction value</p> -->
                <p class="text-caption word-break-word">
                  {{
                    this.cartPersistedInfo?.deliveryInstruction || '(not set)'
                  }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <StepperNavigationBtn
        v-show="!isViewMode"
        :label="stepperNavigationBtnLabel"
        :loading="loading"
        :isReviewOrder="isReviewOrder"
        :cancelAction="cancelEdit"
      />
    </q-form>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import StepperNavigationBtn from './StepperNavigationBtn'

export default {
  name: 'PickUpinfo',
  components: { StepperNavigationBtn },
  props: {
    next: {
      type: Function,
      default: () => {},
    },
    isReviewOrder: {
      type: Boolean,
      default: false,
    },
    cartNumber: {
      type: String,
      default: '',
    },
    fulfillmentObject: {
      type: Object,
      default: () => {
        return {}
      },
    },
    title: {
      type: String,
      default: '',
    },
  },
  created() {
    this.pickupPerson = Object.assign({}, this.currentPickupInfo)
    this.deliveryInstruction = this.cartPersistedInfo?.deliveryInstruction
  },
  data() {
    return {
      loading: false,
      deliveryInstruction: this.cartPersistedInfo?.deliveryInstruction,
      isViewMode: this.isReviewOrder,
      fulfillmentMethodID: this.fulfillmentObject.fulfillmentMethodID,
      pickupPerson: {
        pickUpByName1: '',
        pickUpByPhone1: '',
        pickUpByEmail1: '',
        pickupByDOB1: '',
        pickUpByName2: '',
        pickUpByPhone2: '',
        pickUpByEmail2: '',
        pickupByDOB2: '',
      },
      alternatePickup: null,
    }
  },
  computed: {
    ...mapGetters('common', ['isSlotBookingRequired']),
    ...mapGetters('cart', ['pickupInfoByFulfillmentId', 'currentCartDetails']),
    ...mapGetters('reserveSlot', ['formatBookedSlot']),
    ...mapGetters('persisted', ['selectedFulfillmentCodeName']),
    ...mapGetters('persistedLocal', ['getCartPersistedInfo']),
    cartPersistedInfo() {
      return this.getCartPersistedInfo(this.cartNumber)
    },
    isContactlessdelivery: {
      get() {
        return this.$store.getters['persistedLocal/isContactlessdelivery']
      },
      set(val) {
        this.$store.commit('persistedLocal/SET_CONTACTLESS_DELIVERY', val)
      },
    },
    pickUpMethod: {
      get() {
        return this.cartPersistedInfo?.pickupType || 1
      },
      set(val) {
        if (!this.deliveryInstruction) {
          this.deliveryInstruction = this.cartPersistedInfo?.deliveryInstruction
        }

        this.$store.commit('persistedLocal/SET_CART_PERSISTED_INFO', {
          cartNumber: this.cartNumber,
          data: {
            pickupType: val,
            deliveryInstruction: this.deliveryInstruction,
          },
        })
      },
    },
    currentPickupInfo() {
      return this.pickupInfoByFulfillmentId(this.fulfillmentMethodID)
    },
    pickUpByName1: {
      get() {
        return this.pickupPerson.pickUpByName1 || ''
      },
      set(val) {
        this.$set(this.pickupPerson, 'pickUpByName1', val)
      },
    },
    pickUpByName2: {
      get() {
        return this.pickupPerson.pickUpByName2 || ''
      },
      set(val) {
        this.$set(this.pickupPerson, 'pickUpByName2', val)
      },
    },
    // pickUpByPhone1: {
    //   get() {
    //     return this.pickupPerson.pickUpByPhone1 || ''
    //   },
    //   set(val) {
    //     this.$set(this.pickupPerson, 'pickUpByPhone1', val)
    //   },
    // },
    // pickUpByPhone2: {
    //   get() {
    //     return this.pickupPerson.pickUpByPhone2 || ''
    //   },
    //   set(val) {
    //     this.$set(this.pickupPerson, 'pickUpByPhone2', val)
    //   },
    // },
    pickUpByEmail1: {
      get() {
        return this.pickupPerson.pickUpByEmail1 || ''
      },
      set(val) {
        this.$set(this.pickupPerson, 'pickUpByEmail1', val)
      },
    },
    pickUpByEmail2: {
      get() {
        return this.pickupPerson.pickUpByEmail2 || ''
      },
      set(val) {
        this.$set(this.pickupPerson, 'pickUpByEmail2', val)
      },
    },
    pickupByDOB1: {
      get() {
        return this.pickupPerson.pickupByDOB1
      },
      set(val) {
        this.$set(this.pickupPerson, 'pickupByDOB1', val)
      },
    },
    pickupByDOB2: {
      get() {
        return this.pickupPerson.pickupByDOB2
      },
      set(val) {
        this.$set(this.pickupPerson, 'pickupByDOB2', val)
      },
    },
    adddAlternatePickup: {
      get() {
        return this.alternatePickup !== null
          ? this.alternatePickup
          : this.pickUpByName2
          ? true
          : false
      },
      set(value) {
        this.alternatePickup = value
      },
    },
    objRequest() {
      return {
        orderNumer: this.cartNumber,
        fulfillmentMethodID: this.fulfillmentMethodID,
        locationID: this.currentLocationId,
        pickUpByName1: this.pickUpByName1,
        // pickUpByPhone1: this.unmask(this.pickUpByPhone1, this.phoneMask),
        pickUpByEmail1: this.pickUpByEmail1,
        pickupByDOB1: this.pickupByDOB1,
        pickUpByName2: this.adddAlternatePickup ? this.pickUpByName2 : '',
        // pickUpByPhone2: this.adddAlternatePickup
        //   ? this.unmask(this.pickUpByPhone2, this.phoneMask)
        //   : '',
        pickUpByEmail2: this.adddAlternatePickup ? this.pickUpByEmail2 : '',
        pickupByDOB2: this.adddAlternatePickup ? this.pickupByDOB2 : '',
        deliveryInstruction: this.deliveryInstruction,
        customerID: this.currentCartDetails.customerID,
      }
    },
    formatedCustomerSPBookedSlot() {
      return this.formatBookedSlot('sp')
    },
    // formatedCustomerISBookedSlot() {
    //   return this.formatBookedSlot('is')
    // },
    stepperNavigationBtnLabel() {
      return this.isReviewOrder ? 'Apply' : ''
    },
  },
  methods: {
    openReserSlot(bookingType) {
      this.changeDialogState({
        dialog: 'reserveTimeslot',
        val: true,
        properties: {
          activeTab: bookingType,
        },
      })
    },
    maskPhoneNumber(phoneNumber) {
      if (phoneNumber && phoneNumber.length == 10) {
        return (
          '(' +
          phoneNumber.slice(0, 3) +
          ')-' +
          phoneNumber.slice(3, 6) +
          '-' +
          phoneNumber.slice(6, 10)
        )
      }
      return 'NA'
    },
    isDateAllowed(date) {
      return new Date().toISOString().slice(0, 10).split('-').join('/') > date
    },
    submitFormAndContinue() {
      this.$refs.pickupInforForm.validate().then(async (isValid) => {
        if (isValid) {
          this.loading = true
          this.$store.commit('persistedLocal/SET_CART_PERSISTED_INFO', {
            cartNumber: this.cartNumber,
            data: {
              rateID: this.pickUpMethod,
              deliveryInstruction: this.deliveryInstruction,
            },
          })

          this.$store
            .dispatch('cart/updatePickupInfo', this.objRequest)
            .then((response) => {
              this.loading = false
              this.pickupPerson = Object.assign({}, this.currentPickupInfo)
              if (this.isReviewOrder) {
                this.isViewMode = true
              } else {
                this.next()
              }
            })
        }
        return
      })
    },
    cancelEdit() {
      this.pickupPerson = Object.assign({}, this.currentPickupInfo)
      this.isViewMode = true
      this.deliveryInstruction =
        this.cartPersistedInfo?.deliveryInstruction || ''
    },
    editInfo() {
      this.pickupPerson = Object.assign({}, this.currentPickupInfo)

      if (this.pickUpByName2) {
        this.adddAlternatePickup = true
      } else {
        this.adddAlternatePickup = false
      }

      this.isViewMode = false
      this.deliveryInstruction =
        this.cartPersistedInfo?.deliveryInstruction || ''
      this.isViewMode = false
    },
  },
  watch: {
    currentPickupInfo: {
      handler: function (newVal, oldVal) {
        if (newVal && newVal != oldVal) {
          this.pickupPerson = Object.assign({}, this.currentPickupInfo)
        }
      },
      deep: true,
    },
  },
}
</script>
<style lang="scss">
.cs-review-info {
  .q-field {
    .q-field__bottom {
      max-width: 125px;
    }
  }
}
</style>
