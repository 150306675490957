import { render, staticRenderFns } from "./ShippingRates.vue?vue&type=template&id=5f4361e0"
import script from "./ShippingRates.vue?vue&type=script&lang=js"
export * from "./ShippingRates.vue?vue&type=script&lang=js"
import style0 from "./ShippingRates.vue?vue&type=style&index=0&id=5f4361e0&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QScrollArea from 'quasar/src/components/scroll-area/QScrollArea.js';
import QList from 'quasar/src/components/item/QList.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QRadio from 'quasar/src/components/radio/QRadio.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import qInstall from "../../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(component, 'components', {QCard,QCardSection,QBtn,QSeparator,QScrollArea,QList,QItem,QItemSection,QRadio,QItemLabel,QDialog});
